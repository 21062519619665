<template>
  <div class="about articleBox">
    <div class="container articleContent">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
.about {
  min-height: calc(100vh - 327px);
  &-title {
    margin-top: 0;
    padding: 0px 0 17px;
    border-bottom: 1px solid var(--borderColor);
  }
  .articleContent {
    margin-top: 0;
  }
  &-content {
    padding-top: 24px;
  }
  ol,
  ul,
  li {
    list-style: auto;
  }
  *[type="i"] li {
    list-style-type: lower-roman;
  }
  *[type="a"] li {
    list-style-type: lower-alpha;
  }
  *[type="1"] li {
    list-style-type: decimal;
  }
}
</style>
